<template>
  <v-container>
    <v-row class="mb-10 justify-space-between">
      <v-col>
        <h1 class="text text-h4 titulo">Usuarios</h1>
      </v-col>
      <v-col class="text-end">
        <v-btn class="ml-4" color="success" @click.stop="CreateUpdate">
            <v-icon class="mr-2">fas fa-plus</v-icon>Nuevo
        </v-btn>
      </v-col>
    </v-row>
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="15"
          :search="search"
          :loading="loading"
          :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'fas fa-angle-double-left',
          lastIcon: 'fas fa-angle-double-right',
          prevIcon: 'fas fa-angle-left',
          nextIcon: 'fas fa-angle-right'
          }"
        >
          <template v-slot:top>
            <v-row justify="end">
              <v-col cols="6">
                <v-text-field
              hide-details
              prepend-inner-icon="fas fa-search"
              single-line
              placeholder="Buscar"
              v-model="search"
              ></v-text-field>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.is_seller="{ item }">
            <v-icon v-if="item.is_seller" class="primary--text">fas fa-check</v-icon>
          </template>
          <template v-slot:item.is_active="{ item }">
            <v-switch v-model="item.is_active" @change="changeUserStatus(item)" :disabled="!$can('update','User')"></v-switch>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon @click.stop="CreateUpdate(item)">fas fa-edit</v-icon>
          </template>
        </v-data-table>
  </v-container>
</template>

<script>
import {listUsers,updateUser} from '@/api/auth'
import notifications from '../../utils/notifications'

export default {
  name: 'UsersList',
  components: {
   
  },
  async created(){
    this.loading=true
    this.items = await listUsers()
    this.loading=false
  },
  data: () =>({
    items:[],
    headers: [
          {
            text: 'Nombre',
            align: 'start',
            value: 'first_name',
            class:"table-header"
          },
          { text: 'Apellido', value: 'last_name',class:"table-header" },
          { text: 'Email', value: 'email' ,class:"table-header"},
          { text: 'Rol',value:'user_role.name',class:"table-header"},
          { text: 'Vendedor',value:'is_seller',class:"table-header"},
          { text: 'Activo',value:'is_active',class:"table-header"},
          { text: 'Acciones',value:'actions',class:"table-header"}
        ],
    search:'',
    loading:false
  }),
  methods:{
    CreateUpdate(item){
      this.$router.push({name:'UsersCreateUpdate',params:{id:item.id}})
    },
    async changeUserStatus(item){
      await updateUser(item)
      .then((res)=>{
        notifications.showSuccess('Usuario actualizado correctamente')
      })
      .catch(error=>{
        notifications.showError(error)
      })
    }
    
  },
  computed: {
    numberOfPages () {
        return Math.ceil(this.items.length / this.itemsPerPage)
      },
  }
}
</script>
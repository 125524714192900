const menus=[
        {
          title: 'Comercial',
          items:[
            { icon: 'fas fa-cash-register', text: 'Cotizaciones', link:'sales',gate:'SalesNote'},
            { icon: 'fas fa-users-between-lines', text: 'Clientes', link:'customers',gate:'Customer'},
            { icon: 'fas fa-book-bookmark', text: 'Productos', link:'products',gate:'Product'},
            { icon: 'tune', text: 'Familia y Línea', link:'products/family-line',gate:'Product' },
          ]
        }, 
        {
          title: 'Producción',
          items:[
            { icon: 'fas fa-hard-hat', text: 'Órdenes de Trabajo', link:'production/work-orders',gate:'WorkOrder'},
            { icon: 'fas fa-bars-staggered', text: 'Pre-Produccion', link:'production/products-in-prestage',gate:'WorkOrder'},
            { icon: 'fas fa-arrow-down-short-wide', text: 'Planificador', link:'production/main-planner2',gate:'Planner'},
            { icon: 'fas fas fa-gamepad', text: 'Control de Piso', link:'production/floor-control',gate:'FloorControl'},
            { icon: 'fas fas fa-table', text: 'Bitacora de Produccion', link:'reports/production-bitacora',gate:'Planner'},
            { icon: 'fas fa-cart-shopping', text: 'Bitacora de Consumo', link:'reports/consume-bitacora',gate:'Planner'},
            { icon: 'event_available', text: 'Compromisos', link:'production/compromise-calendar',gate:'Calendar'},
          ]
        }, 
        {
          title: 'Inventarios',
          items:[
            { icon: 'fas fa-boxes', text: 'Inventario', link:'stocks/stock',gate:'Stock'},
            { icon: 'fas fa-clock-rotate-left', text: 'Transacciones', link:'stocks/transactions',gate:'Stock'},
            { icon: 'fas fa-shopping-basket', text: 'Ordenes de Compra', link:'stocks/purchase-orders',gate:'PurchaseOrder'},
            { icon: 'fas fa-dolly-flatbed', text: 'Entradas/Salidas', link:'stocks/create-transaction',gate:'StockTransaction'},
            { icon: 'fas fa-cart-shopping', text: 'Bitacora de Consumo por OT', link:'reports/consume-ot-bitacora',gate:'StockTransaction'},
            { icon: 'fas fa-box', text: 'Artículos', link:'stocks/articles',gate:'StockArticle'},
            { icon: 'fas fa-cog', text: 'Mantenedores', link:'stocks/configuration',gate:'StockConfiguration'},
          ]
        }, 
        {
          title: 'Finanzas',
          items:[
            { icon: 'fas fa-file-invoice-dollar', text: 'Facturación', link:'sales/invoices',gate:'Invoice'},
            { icon: 'fas fa-rss', text: 'Documentos Softnet', link:'sales/softnet-documents',gate:'Invoice'},
            { icon: 'fas fa-rss', text: 'Documentos Kame', link:'sales/kame-documents',gate:'Invoice'}
          ]
        },
        {
          title: 'Gestión',
          items:[
            { icon: 'fas fa-flag-checkered', text: 'Post Costeo', link:'reports/post-cost',gate:'PostCost'},
            { icon: 'fas fa-chart-bar', text: 'Ventas por Producto', link:'reports/product-sales',gate:'SalesByProduct'},
            { icon: 'fas fa-chart-bar', text: 'OT Valorizada', link:'reports/work-order-money',gate:'WorkOrderVal'},
            { icon: 'fas fa-chart-area', text: 'Facturación por Producto', link:'reports/product-invoice',gate:'InvoiceByProduct'},
            { icon: 'fas fa-chart-line', text: 'Facturación de OT', link:'reports/work-order-invoice',gate:'InvoiceByWorkOrder'},
            { icon: 'leaderboard', text: 'Ventas por Familia', link:'reports/family-sales',gate:'SalesByFamily'},
            { icon: 'fas fa-truck-droplet', text: 'Material Comprometido', link:'reports/supply-orders',gate:'CompromiseMaterial'},
            { icon: 'fas fa-truck-fast', text: 'Distribucion', link:'reports/distribution-list',gate:'Distribution'},
          ]
        },
        {
          title: 'Configuración',
          icon:'fas fa-cog',
          items:[
            { icon: 'fas fa-cogs', text: 'Procesos', link:'products/processes', gate:'Cost'},
          { icon: 'fas fa-calculator', text: 'Materiales', link:'products/supplies',gate:'Cost' },
          { icon: 'tune', text: 'Grupos', link:'products/costs/groups-units',gate:'Cost' },
          { icon: 'fas fa-rocket', text: 'Areas de Producción', link:'production/machines',gate:'Cost' },
          { icon: 'dns', text: 'Etapas de Pre-producción', link:'production/stages',gate:'Cost' },
          { icon: 'people', text: 'Usuarios', link:'users',gate:'User'},
          { icon: 'tune', text: 'Parámetros', link:'parameters',gate:'Parameter'},
          ]
        } 
      ]
export default menus;
const access_list = [
    {
      "name": "Super Usuario",
      "action": "manage",
      "subject": "all",
      "condition": "{}"
    },
    //Customer ------//
    {
      "name": "customer_read",
      "action": "read",
      "subject": "Customer",
      "condition": "{}"
    },
    {
      "name": "customer_create",
      "action": "create",
      "subject": "Customer",
      "condition": "{}"
    },
    {
      "name": "customer_update",
      "action": "update",
      "subject": "Customer",
      "condition": "{\"sellerId\":{user.id}}"
    },
    {
      "name": "customer_delete",
      "action": "delete",
      "subject": "Customer",
      "condition": "{}"
    },
    {
      "name": "customer_manage",
      "action": "manage",
      "subject": "Customer",
      "condition": "{}"
    },
    //SalesNote ------//
    {
      "name": "salesNote_read",
      "action": "read",
      "subject": "SalesNote",
      "condition": "{}"
    },
     {
      "name": "salesNote_read_all",
      "action": "readAll",
      "subject": "SalesNote",
      "condition": "{}"
    },
    {
      "name": "salesNote_read_cost",
      "action": "readCost",
      "subject": "SalesNote",
      "condition": "{}"
    },
    {
      "name": "salesNote_save_product_below_cost",
      "action": "save_product_below_cost",
      "subject": "SalesNote",
      "condition": "{}"
    },
    {
      "name": "salesNote_save_product_below_price",
      "action": "save_product_below_price",
      "subject": "SalesNote",
      "condition": "{}"
    },
    {
      "name": "salesNote_save_product_below_margin",
      "action": "save_product_below_margin",
      "subject": "SalesNote",
      "condition": "{}"
    },
    {
      "name": "salesNote_manage",
      "action": "manage",
      "subject": "SalesNote",
      "condition": "{}"
    },
    {
      "name": "salesNote_delete",
      "action": "delete",
      "subject": "SalesNote",
      "condition": "{}"
    },
    {
      "name": "salesNote_update",
      "action": "update",
      "subject": "SalesNote",
      "condition": "{}"
    },
    {
      "name": "salesNote_update_with_OT",
      "action": "update_with_OT",
      "subject": "SalesNote",
      "condition": "{}"
    },
    {
      "name": "salesNote_apply_discount",
      "action": "apply_discount",
      "subject": "SalesNote",
      "condition": "{}"
    },
    {
      "name": "salesNote_duplicate",
      "action": "duplicate",
      "subject": "SalesNote",
      "condition": "{}"
    },
    {
      "name": "salesNote_reject",
      "action": "reject",
      "subject": "SalesNote",
      "condition": "{}"
    },
    //Costs ------//
    {
      "name": "Cost_manage",
      "action": "manage",
      "subject": "Cost",
      "condition": "{}"
    },
    //Product ------//
    {
      "name": "Product_read",
      "action": "read",
      "subject": "Product",
      "condition": "{}"
    },
    {
      "name": "Product_create",
      "action": "create",
      "subject": "Product",
      "condition": "{}"
    },
    {
      "name": "Product_update",
      "action": "update",
      "subject": "Product",
      "condition": "{}"
    },
    {
      "name": "Product_manage",
      "action": "manage",
      "subject": "Product",
      "condition": "{}"
    },
    //WorkOrder ------//
    {
      "name": "WorkOrder_read",
      "action": "read",
      "subject": "WorkOrder",
      "condition": "{}"
    },
    {
      "name": "WorkOrder_delete",
      "action": "delete",
      "subject": "WorkOrder",
      "condition": "{}"
    },
    {
      "name": "WorkOrder_update",
      "action": "update",
      "subject": "WorkOrder",
      "condition": "{}"
    },
    {
      "name": "WorkOrder_manage",
      "action": "manage",
      "subject": "WorkOrder",
      "condition": "{}"
    },   
    //Planner ------//
    {
      "name": "Planner_manage",
      "action": "manage",
      "subject": "Planner",
      "condition": "{}"
    },
    //Bitacora
    {
      "name": "ProductionBitacora_delete",
      "action":"delete",
      "subject":"ProductionBitacora",
      "condition":"{}"
    },
    //FloorControl ------//
    {
      "name": "FloorControl_manage",
      "action": "manage",
      "subject": "FloorControl",
      "condition": "{}"
    },
    //Calendar ------//
    {
      "name": "Calendar_manage",
      "action": "manage",
      "subject": "Calendar",
      "condition": "{}"
    },
    //Purchase Order ------//
    {
      "name": "PurchaseOrder_read",
      "action": "read",
      "subject": "PurchaseOrder",
      "condition": "{}"
    },
    {
      "name": "PurchaseOrder_create",
      "action": "create",
      "subject": "PurchaseOrder",
      "condition": "{}"
    },
    {
      "name": "PurchaseOrder_update",
      "action": "update",
      "subject": "PurchaseOrder",
      "condition": "{}"
    },
    {
      "name": "PurchaseOrder_aproove",
      "action": "aproove",
      "subject": "PurchaseOrder",
      "condition": "{}"
    },
    {
      "name": "PurchaseOrder_revert",
      "action": "revert",
      "subject": "PurchaseOrder",
      "condition": "{}"
    },
    {
      "name": "PurchaseOrder_delete",
      "action": "delete",
      "subject": "PurchaseOrder",
      "condition": "{}"
    },
    {
      "name": "PurchaseOrder_read",
      "action": "read",
      "subject": "PurchaseOrder",
      "condition": "{}"
    },
    {
      "name": "PurchaseOrder_print",
      "action": "print",
      "subject": "PurchaseOrder",
      "condition": "{}"
    },
    {
      "name": "PurchaseOrder_manage",
      "action": "manage",
      "subject": "PurchaseOrder",
      "condition": "{}"
    },
    //Invoice ------//
    {
      "name": "Invoice_read",
      "action": "read",
      "subject": "Invoice",
      "condition": "{}"
    },
    {
      "name": "Invoice_create",
      "action": "create",
      "subject": "Invoice",
      "condition": "{}"
    },
    {
      "name": "Invoice_udpate",
      "action": "update",
      "subject": "Invoice",
      "condition": "{}"
    },
    {
      "name": "Invoice_delete",
      "action": "delete",
      "subject": "Invoice",
      "condition": "{}"
    },
    {
      "name": "Invoice_manage",
      "action": "manage",
      "subject": "Invoice",
      "condition": "{}"
    },
    //Stock ------//
    {
      "name": "Stock_read",
      "action": "read",
      "subject": "Stock",
      "condition": "{}"
    },
    {
      "name": "Stock_manage",
      "action": "manage",
      "subject": "Stock",
      "condition": "{}"
    },
    {
      "name": "StockTransaction_manage",
      "action": "manage",
      "subject": "StockTransaction",
      "condition": "{}"
    },
    {
      "name": "StockArticle_read",
      "action": "read",
      "subject": "StockArticle",
      "condition": "{}"
    },
    {
      "name": "StockArticle_create",
      "action": "create",
      "subject": "StockArticle",
      "condition": "{}"
    },
    {
      "name": "StockArticle_update",
      "action": "update",
      "subject": "StockArticle",
      "condition": "{}"
    },
    {
      "name": "StockArticle_manage",
      "action": "manage",
      "subject": "StockArticle",
      "condition": "{}"
    },
    {
      "name": "StockConfig_manage",
      "action": "manage",
      "subject": "StockConfiguration",
      "condition": "{}"
    },
    //Postcost------//
    {
      "name": "PostCost_manage",
      "action": "manage",
      "subject": "PostCost",
      "condition": "{}"
    },
    {
      "name": "PostCost_read",
      "action": "read",
      "subject": "PostCost",
      "condition": "{}"
    },
    {
      "name": "SalesByProduct_read",
      "action": "read",
      "subject": "SalesByProduct",
      "condition": "{}"
    },
    {
      "name": "WorkOrderVal_read",
      "action": "read",
      "subject": "WorkOrderVal",
      "condition": "{}"
    },
    {
      "name": "InvoiceByProduct_read",
      "action": "read",
      "subject": "InvoiceByProduct",
      "condition": "{}"
    },
    {
      "name": "InvoiceByWorkOrder_read",
      "action": "read",
      "subject": "InvoiceByWorkOrder",
      "condition": "{}"
    },
    {
      "name": "SalesByFamily_read",
      "action": "read",
      "subject": "SalesByFamily",
      "condition": "{}"
    },
    {
      "name": "CompromiseMaterial_read",
      "action": "read",
      "subject": "CompromiseMaterial",
      "condition": "{}"
    },
    {
      "name": "Distribution_read",
      "action": "read",
      "subject": "Distribution",
      "condition": "{}"
    }, 
    //SOFTNET ------//
    {
      "name": "Softnet_manage",
      "action": "manage",
      "subject": "Softnet",
      "condition": "{}"
    },
    //KAME -----//
    {
      "name": "Kame_manage",
      "action": "manage",
      "subject": "Kame",
      "condition": "{}"
    }

]

export default access_list;
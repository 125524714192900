<template>
<v-container>
  <v-skeleton-loader
          v-if="loading"
          v-bind="attrs"
          type="actions,text,text,text,text,card,card"
  ></v-skeleton-loader>
  <div v-if="!loading">
    <ValidationObserver v-slot="{handleSubmit}">
    <v-form @submit.prevent="handleSubmit(saveCustomer)">
      <v-row class="mb-10 justify-space-between">
        <v-col>
          <h1 class="text text-h4 titulo"><v-icon class="mr-2">{{customer.id ? 'fas fa-edit':'fas fa-user-plus'}}</v-icon>{{customer.id ? 'Actualizar Cliente':'Crear Cliente'}}</h1>
        </v-col>
        <v-col class="d-flex justify-end">
          
              <v-switch
              class="mt-0 pt-2 mr-2"
              inset
              v-model="customer.status"
              :color="customer.status ? 'success':'error'"
              :label="customer.status ? 'Activo':'Inactivo'"
            ></v-switch>
            <v-btn :loading="saveLoading" type="submit" color="success"><v-icon class="mr-2">fas fa-sd-card</v-icon>Guardar</v-btn>
            <v-btn @click="$router.push('/customers')" text class="ml-2" ><v-icon>fas fa-chevron-left</v-icon></v-btn>
          
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ValidationProvider name="RUT" rules="required" v-slot="{ errors }">
            <v-text-field
            dense
            label='RUT'
            prepend-icon="fas fa-id-badge"
            v-model='customer.rut'
            hint="Ingresar número con guión y dígito verificador"
            outlined
            :rules="[rules.rut]"
            :error-messages="errors"
            :append-icon="rutValido?'fas fa-check':''"
            @change="()=>{}"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col>
          <ValidationProvider name="Giro" rules="required" v-slot="{ errors }">
            <v-text-field
            dense
            prepend-icon="fas fa-exclamation-circle"
            label='Giro'
            v-model='customer.giro'
            outlined
            :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ValidationProvider name="Razón Social" rules="required" v-slot="{ errors }">
            <v-text-field
            dense
            label='Razón Social'
            prepend-icon="fas fa-file-alt"
            v-model='customer.name'
            outlined
            :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col>
          <ValidationProvider name="Fantasía" rules="required" v-slot="{ errors }">
            <v-text-field
            dense
            label='Nombre de Fantasía'
            prepend-icon="fas fa-hat-wizard"
            v-model='customer.fantasy'
            outlined
            :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <ValidationProvider name="Teléfono" rules="required" v-slot="{ errors }">
            <v-text-field
            dense
            label='Teléfono'
            prepend-icon="fas fa-phone"
            v-model='customer.phone'
            outlined
            :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
        <v-col>
          <ValidationProvider name="Condición de Pago" rules="required" v-slot="{ errors }">
            <v-text-field
            dense
            label='Condición de Pago (Días)'
            v-model='customer.payment'
            prepend-icon="fas fa-calendar-alt"
            type='number'
            :min="0"
            outlined
            :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
           <ValidationProvider name="Vendedor" rules="required" v-slot="{ errors }">
            <seller-selection 
              v-model="customer.seller"
              icon="fas fa-id-badge"
              :errors="errors"/>
          </ValidationProvider>
        </v-col>
        <v-col>
          <ValidationProvider name="Comisión" rules="required" v-slot="{ errors }">
            <v-text-field
            dense
            label='Comisión de Agencia'
            prepend-icon="mdi-chart-timeline-variant"
            v-model='customer.com_agencia'
            type='number'
            :min="0"
            outlined
            :error-messages="errors"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
    </v-form>
    </ValidationObserver>
  </div>
  <v-row>
    <v-col>
      <v-card>
        <v-card-title class="justify-space-between">
          Direcciones
          <v-btn medium color="success" @click="addAddress=true"><v-icon>fas fa-plus</v-icon></v-btn>
        </v-card-title>
        <v-expansion-panels>
          <v-expansion-panel v-for="address in customer.addresses" :key="address.name">
            <v-expansion-panel-header>
                <div>{{address.name}}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="pa-2"><v-icon class="mr-2">fas fa-road</v-icon>{{address.street}} {{address.number}} {{address.suite}}</v-row>
              <v-row class="pa-2 justify-space-between">
                <div>
                  <v-icon class="mr-2">fas fa-map-marked-alt</v-icon>{{address.comune}} {{address.region}}
                </div>
                <v-icon small class="error--text" @click="removeAddress(address)">fas fa-trash</v-icon></v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
    <v-col>
      <v-card>
        <v-card-title class="justify-space-between">
          Contactos
          <v-btn medium color="success" @click="addContact=true"><v-icon>fas fa-plus</v-icon></v-btn>
        </v-card-title>
        <v-expansion-panels>
          <v-expansion-panel v-for="contact in customer.contacts" :key="contact.email">
            <v-expansion-panel-header>
                <div>{{`${contact.first_name} ${contact.last_name}`}}</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="pa-2"><v-icon class="mr-2">fas fa-envelope</v-icon>{{contact.email}}</v-row>
              <v-row class="pa-2 justify-space-between">
                <div>
                  <v-icon class="mr-2">fas fa-phone</v-icon>{{contact.phone}}
                </div>
                <v-icon small class="error--text" @click="deleteContact(contact)" >fas fa-trash</v-icon></v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card>
    </v-col>
  </v-row>
  <v-dialog v-model="addAddress" max-width="500">
    <v-card class="pa-6">
      <v-card-title><v-icon class="mr-2">fas fa-plus</v-icon>Dirección</v-card-title>
      <address-create-update @add="addNewAddress" @close="addAddress=false"/>
    </v-card>
  </v-dialog>
  <v-dialog v-model="addContact" max-width="500">
    <v-card class="pa-6">
      <v-card-title><v-icon class="mr-2">fas fa-plus</v-icon>Contacto</v-card-title>
      <contact-create-update @add="addNewContact" @close="addContact=false"/>
    </v-card>
  </v-dialog>
  <confirm 
    v-model="showConfirm" 
    message="El RUT ingresado ya existe. Desea crear una sucursal?" 
    @ok="()=>{
      if(customer.id) modifyCustomer()
      else addNewCustomer()
      }"
    @cancel="()=>{customer.branch_office=0;showConfirm=false}"/>
</v-container>
</template>

<script>
import regions_comunes from '@/utils/regions_comunes.json'
import SellerSelection from '@/components/customers/SellerSelection'
import {getCustomer,createCustomer, updateCustomer,createAddress,deleteAddress,createContact,deleteContact,fetchCustomers} from '@/api/customers'
import AddressCreateUpdate from '@/components/customers/AddressCreateUpdate'
import ContactCreateUpdate from '@/components/customers/ContactCreateUpdate'
import notifications from '@/utils/notifications'
import {validaRUT }from '@/utils/rut_validator'


export default {
  name:'CreateupdateCustomer',
  components: { SellerSelection,AddressCreateUpdate, ContactCreateUpdate,},
  data:()=>({
    customer:{
      addresses:[],
      contacts:[],
      seller:'',
      status:true,
      branch_office:0,
    },
    addAddress:false,
    address:'',
    addContact:false,
    contact:'',
    alerta:true,
    saveLoading:false,
    loading:false,
    showConfirm:false,
    rules:{
      rut:value=>validaRUT(value)||'Rut Inválido'
    }
  }),
  
  async created(){
    if(this.$route.params.customerId){
      this.loading=true
      await getCustomer(this.$route.params.customerId)
      .then((res)=>{
        this.customer = res
        this.customer.seller=this.customer.seller?this.customer.seller:''
        this.loading=false
      })
      .catch((err)=>{
        notifications.showError('Error cargando datos del cliente')
      })
    }
  },
  methods:{
    addNewAddress(address){
      this.customer.addresses.push({
          id:null,
          name:address.name,
          street:address.street,
          number:address.number,
          comune:address.comune,
          region:address.region,
          suite:address.suite,
      })
    },
    async removeAddress(address){
      const index = this.customer.addresses.indexOf(address);
      if(address.id==null){
        this.customer.addresses.splice(index, 1);
      }
      else{
        await deleteAddress(address)
        .then(async ()=>{
          this.customer.addresses.splice(index, 1);
          notifications.showInfo('Dirección Borrada')
        })
      }
      this.address=''
    },
    addNewContact(contact){
      this.customer.contacts.push({
          id:null,
          first_name:contact.first_name,
          last_name:contact.last_name,
          email:contact.email,
          phone:contact.phone,
      })
    },
    async deleteContact(contact){
      const index = this.customer.contacts.indexOf(contact);
      if(contact.id==null){
        this.customer.contacts.splice(index, 1);
      }
      else{
        await deleteContact(contact)
        .then(async ()=>{
          this.customer.contacts.splice(index, 1);
          notifications.showInfo('Contacto Borrado')
        })
      }
      this.contact=''
    },
    async saveCustomer(){
      if(this.customer.addresses.length==0){
        notifications.showError('Es necesario agregar una Dirección')
        return 0
      }
      if(this.customer.contacts.length==0){
        notifications.showError('Es necesario agregar un Contacto')
        return 0
      }
      if(!this.rutValido){
        notifications.showError('RUT Inválido')
        return 0
      }
      if(this.customer.id && this.saveValidation){
        this.modifyCustomer()
      }
      if(this.saveValidation){
        //Verifica si el rut ya existe
        await fetchCustomers('',1,'','','','',this.customer.rut)
        .then((res)=>{
          if(res.count>0){
            this.customer.branch_office=res.count+1
            this.showConfirm=true
          }
          else {
            this.addNewCustomer()
          }
        })
        .catch(()=>{
          notifications.showError('Error verificando datos del cliente')
        })
      }
    },
    async modifyCustomer(){
      this.saveLoading=true
        await updateCustomer(this.customer)
        .then(()=>{
          //creando las nuevas direcciones
          this.customer.addresses.forEach(async address => {
            await this.saveNewAddress(address)
          });
          //creando nuevos contactos
          this.customer.contacts.forEach(async contact=>{
            await this.saveNewContact(contact)
          })
        notifications.showSuccess('Cliente '+ this.customer.name + ' Actualizado')
        this.$router.push('/customers')
        this.saveLoading=false
        })
        .catch(()=>{
          notifications.showError('Error actualizando el cliente')
          this.saveLoading=false
        })
    },
    async addNewCustomer(){
      this.saveLoading=true
        await createCustomer(this.customer)
        .then(async customer=>{
          this.customer.id=customer.id
          //creando las nuevas direcciones
          this.customer.addresses.forEach(async address => {
            await this.saveNewAddress(address)
          });
          //creando nuevos contactos
          this.customer.contacts.forEach(async contact=>{
            await this.saveNewContact(contact)
          })
        notifications.showSuccess('Cliente '+ this.customer.name + ' Actualizado')
        this.$router.push('/customers')
        this.saveLoading=false
        })
        .catch(()=>{
          notifications.showError('Error creando el cliente')
          this.saveLoading=false
        })
    },
    async saveNewAddress(address){
      if(address.id==null){
        address.customer = this.customer.id
        await createAddress(address)
        .then(()=>{
          notifications.showInfo('Nueva Dirección Creada')
        })
      }
    },
    async saveNewContact(contact){
      if(contact.id==null){
        contact.customer=this.customer.id
        await createContact(contact)
        .then(()=>{
          notifications.showInfo('Nuevo Contacto Creado')
        })
      }
    }
  },
  computed:{
    saveValidation(){
      return this.customer.addresses.length>0 && this.customer.contacts.length>0
    },
    rutValido(){
      var valido = false
      if(this.customer.rut){
        valido = validaRUT(this.customer.rut)
      }
      return valido
    },
  }
}
</script>
import axios from "axios"
import store from '@/store'

//const token = store.getters.getToken
//axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token')

export async function createSalesNote(item) {

    return await axios.post("sales/sales-note", item,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    )
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateSalesNote(id,data) {
    return await axios.patch(`sales/sales-note/${id}`, data,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    )
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function RejectSalesNote(id,data) {
    return await axios.post(`sales/sales-note/${id}/reject`, data)
    .then(res => (res.data))
    .catch(e => {
        throw e.response.data.error
    })
}

export async function fetchSalesNote(sort = '', search = '', created_after = '', created_before = '', customer = '', seller = '', has_work_order = '', page = '', page_size = '',status='') {

    return await axios.get(`sales/sales-note?ordering=${sort}&search=${search}&page=${page}&page_size=${page_size}&created_after=${created_after}&created_before=${created_before}&customer=${customer}&customer__seller=${seller}&has_work_order=${has_work_order}&status=${status}`)
        .then(res => {
            const salesNotes = res.data
            return salesNotes
        })
        .catch(e => {
            throw e
        })
}

export async function fetchDTE(query='') {
    return await axios.get(`sales/dte?${query}`)
        .then(res => {
            console.log(res.data)
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createDTE(dte) {
    return await axios.post('sales/dte',dte)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function updateDTE(dte) {
    return await axios.patch(`sales/dte/${dte.id}`,dte)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createPayment(payment) {
    return await axios.post('sales/payment',payment)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function getWorkOrderforDTE(id){
    return await axios.get(`sales/dte-work-order/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}


export async function deletePayment(id) {
    return await axios.delete(`sales/payment/${id}`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}


export async function fetchDayGraph(query='') {
    return await axios.get(`sales/daygraph?${query}`)
        .then(res => {
            console.log(res.data)
            return res.data  
        })
        .catch(e => {
            throw e
        })
}



export async function getSalesNote(id) {
    return await axios.get(`sales/sales-note/${id}`)
        .then(async res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}
export async function deleteSalesNote(id) {
    return await axios.delete(`sales/sales-note/${id}`)
        .then(async res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function fetchStatus() {
    return await axios.get(`sales/status`)
        .then(res => {
            return res.data
        })
        .catch(e => {
            throw e
        })
}

export async function createInvoice(invoice) {
    return axios.post('/sales/invoice', invoice)
        .then((res => {
            return res.data
        }))
        .catch(e => {
            throw e
        })
}

export async function deleteInvoice(id) {
    return axios.delete(`/sales/invoice/${id}`)
        .then((res => {
            return res.data
        }))
        .catch(e => {
            throw e
        })
}

export async function fetchDistribution(query){
    return await axios.get(`sales/distribution?${query}`)
        .then(res => {
            return res.data
        }).catch(e => {
            throw e
        })
}

export async function createDistribution(distribution){
    return await axios.post('/sales/distribution',distribution)
        .then(res => {
            return res.data
        }).catch(e => {
            throw e
        })
}
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mb-4 justify-space-between"},[_c('v-col',[_c('h1',{staticClass:"text text-h4 titulo"},[_c('v-icon',{staticClass:"mr-2 align-items-center"},[_vm._v("fas fa-city")]),_vm._v("Clientes")],1)]),_c('v-col',{staticClass:"text-end d-flex justify-end align-center"},[(_vm.factronicaEnabled)?_c('import-customer-button'):_vm._e(),(_vm.$can('create', 'Customer'))?_c('v-btn',{staticClass:"mr-4",attrs:{"to":"customers/import","color":"primary","text":""}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-file-import")]),_vm._v("Importar ")],1):_vm._e(),(_vm.$can('create', 'Customer'))?_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.CreateUpdate($event)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-plus")]),_vm._v("Nuevo ")],1):_vm._e()],1)],1),_c('v-card',{staticClass:"pa-4"},[_c('v-row',{staticClass:"mb-10 justify-space-between"},[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":10,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalCustomers,"item-class":"table-body","footer-props":{
        showFirstLastPage: true,
        firstIcon: 'fas fa-angle-double-left',
        lastIcon: 'fas fa-angle-double-right',
        prevIcon: 'fas fa-angle-left',
        nextIcon: 'fas fa-angle-right',
        itemsPerPageOptions: [10, 20, 30, 40, 50,100],
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"fas fa-search","disabled":_vm.loading},on:{"keyup":_vm.searchTimeOut},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)]},proxy:true},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"chip justify-center",attrs:{"color":item.status ? 'success':'error',"label":"","small":"","dark":""}},[_vm._v(" "+_vm._s(item.status ? 'Activo':'Inactivo')+" ")])]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" "+_vm._s(item.branch_office>0?("[" + (item.branch_office) + "]"):'')+" ")]}},{key:"item.seller",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.seller?(item.seller.first_name + ' ' + item.seller.last_name):'')+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(_vm.$can('update', _vm.subject('Customer',item)))?_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.CreateUpdate(item)}}},[_vm._v("fas fa-edit")]):_vm._e()]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.showDetail),callback:function ($$v) {_vm.showDetail=$$v},expression:"showDetail"}},[_c('v-card',[_c('customer-card',{attrs:{"selected":_vm.selected}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
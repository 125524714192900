import Vue from 'vue'
import Vuex from 'vuex'
import { me } from '@/api/auth'
import { fetchCompany } from '@/api/parameters'
import {fetchStages} from '@/api/production'
import {fetchSellers} from '@/api/auth'
import {fetchGroups,fetchFamily,fetchLines} from '@/api/products'
import {fetchStores} from '@/api/stocks'
import ability from '../config/ability';
import updateAbility from '../config/updateAbility'
import interpolate from 'interpolate'
import createPersistedState from "vuex-persistedstate";
import unit_list from '@/utils/unit_list'
import { getPaymentRequest } from '../api/auth'

import production from './production'
import parameters from './parameters'


Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        paths: ['company','stages','sellers','groups','families','lines','stores','payment_request','userParameters']
    })],
    modules: {
        production:production,
        parameters:parameters
    },
    state: {
        user: null,
        token: localStorage.getItem('access_token') || null,
        company: '',
        sellers: '',
        stages:'',
        groups:'',
        lines: '',
        families: '',
        stores:'',
        units:unit_list,
        payment_request:''
    },
    mutations: {
        SET_LOGIN: (state, data) => {
            state.user = data;
        },
        SET_LOGOUT: (state) => {
            state.user = null;
            state.token = null;
        },
        SET_PARAMETERS: (state, data) => {
            state.company = data;
        },
        SET_SELLERS: (state, data) => {
            state.sellers = data;
        },
        SET_STAGES:(state,data)=>{
            state.stages=data
        },
        SET_GROUPS:(state,data)=>{
            state.groups=data
        },
        SET_LINES:(state,data)=>{
            state.lines=data
        },
        SET_FAMILIES:(state,data)=>{
            state.families=data
        },
        SET_STORES:(state,data)=>{
            state.stores=data
        },
        SET_PAYMENT_REQUEST:(state,data)=>{
            state.payment_request=data
        }
    },
    getters: {
        getUser: state => {
            return state.user;
        },
        getToken: state => {
            return state.token
        },
        getCompany: state => {
            return state.company
        },
        getSellers: state => {
            return state.sellers
        },
        getStages: state=>{
            return state.stages
        },
        getUnits:state=>{
            return state.units
        },
        getGroups:state=>{
            return state.groups
        },
        getLines:state=>{
            return state.lines
        },
        getFamilies:state=>{
            return state.families
        },
        getStores:state=>{
            return state.stores
        },
        getPaymentRequest:state=>{
            return state.payment_request
        }
    },
    actions: {
        setLogin: (context, user) => {
            context.commit("SET_LOGIN", user);
            context.commit("SET_USER_PARAMETERS",user.parameters);
        },
        logout: (context) => {
            context.commit("SET_LOGOUT")
        },
        getMe: async(context) => {
            await me()
                .then(user => {
                    context.commit("SET_LOGIN", user);
                    const permissions = user.user_role.roles_access
                    const rules = []
                    permissions.forEach(p => {
                        const conditionString = interpolate(p.condition, { user })
                        rules.push({
                            action: p.action,
                            subject: p.subject,
                            conditions: JSON.parse(conditionString)
                        })
                    });

                    updateAbility(user)
                })
                .catch((e) => {
                    context.commit("SET_LOGIN", null)
                    throw e
                })
        },
        getParameters: async(context) => {
            await fetchCompany()
                .then(data => {
                    context.commit("SET_PARAMETERS", data);
                })
        },
        getSellers: async(context) => {
            await fetchSellers()
                .then(data => {
                    context.commit("SET_SELLERS", data);
                })
        },
        getStages:async(context)=>{
            await fetchStages()
                .then(data=>{
                    context.commit("SET_STAGES", data)
                })
        },
        getGroups: async(context) => {
            await fetchGroups()
            .then(data => {
                context.commit("SET_GROUPS", data);
            })
        },
        getLines: async(context)=>{
            await fetchLines()
            .then(data=>{
                context.commit("SET_LINES", data)
            })
        },
        getFamilies: async(context) => {
            await fetchFamily()
            .then(data => {
                context.commit("SET_FAMILIES", data);
            })
        },
        getStores: async(context) => {
            await fetchStores()
            .then(data => {
                context.commit("SET_STORES", data);
            })
        },
        getPaymentRequest: async(context) => {
            await getPaymentRequest()
            .then(data => {
                context.commit("SET_PAYMENT_REQUEST", data);
            })
        }
    },
})